import { useState, useEffect, useCallback } from 'react';
import { experiences } from 'services';
import { skeletonFactory, useHandleRequest } from 'shared';
import { configurations } from 'services';
import { useAzureStorage } from 'shared';
import { CONTAINERS } from 'pages/shared';
import { settings, KEY_SETTINGS } from 'settings';

const experienceDummy = skeletonFactory.experience();

const experienceEmpty = {
  fields: [],
  pages: [],
  mediaFiles: [],
  icons: [],
  articles: [],
  quizQuestions: [],
  readToken: ''
};

export function useExperience(id) {
  const [experienceData, setExperienceData] = useState(id ? experienceDummy : { ...experienceEmpty });
  const { request, errorInfo, setErrorInfo } = useHandleRequest();
  const { getReadSasToken, readSasToken } = useAzureStorage(CONTAINERS.MEDIA);
  const [experienceQRDemo, setExperienceQRDemo] = useState();

  useEffect(() => {
    async function getData() {
      await request(async () => {
        if (id) {
          const response = await experiences.getById(id);
          if (response) {
            setExperienceData(response.data || {});
            const experienceAppUrl = settings.getSetting(KEY_SETTINGS.EXPERIENCE_APP_URL);
            setExperienceQRDemo({ url: `${experienceAppUrl || ''}?experienceId=${id}` });
          }
        } else {
          const response = await configurations.get();
          if (response) {
            setExperienceData(prevExperience => ({ ...prevExperience, ...response.data }));
          }
          getReadSasToken();
        }
      }, false);
    }
    getData();
  }, [request, id]);

  useEffect(() => {
    setExperienceData(prevExperience => ({ ...prevExperience, readToken: readSasToken }));
  }, [readSasToken]);

  const updateExperience = useCallback(
    async experience => {
      await request(async () => {
        setExperienceData(experience);
        await experiences.update(id, experience);
      });
    },
    [request, id]
  );

  const createExperience = useCallback(
    async experience => {
      await request(async () => {
        setExperienceData(experience);
        await experiences.create(experience);
      });
    },
    [request]
  );

  return {
    experience: experienceData,
    errorInfo,
    setErrorInfo,
    setExperienceData,
    createExperience,
    updateExperience,
    experienceQRDemo
  };
}
