import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Typography } from '@material-ui/core';
import { NAME_SPACE } from 'i18n';
import { ButtonAdd } from 'shared';
import { useViewAnswersStyles } from './viewAnswers.styles';
import { ViewAnswer } from './ViewAnswer';

export function ViewAnswers({ answers = [], onAdd, onChange, onDelete, disabled }) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useViewAnswersStyles();

  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={12} className={classes.titleContainer}>
          <div className={classes.subtitle}>
            <Typography variant="body1" className={classes.title}>
              {t('quizQuestionAnswers.name')}
            </Typography>
            <ButtonAdd onClick={onAdd} disabled={disabled} />
          </div>
        </Grid>
        <Grid item xs={12} className={classes.answersContainer}>
          {answers.map((answer, index) => (
            <>
              <ViewAnswer key={index} answer={answer} onChange={onChange} onDelete={onDelete} disabled={disabled} />
              {index < answers.length - 1 && <Divider variant="middle" className={classes.divider} />}
            </>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

ViewAnswers.propTypes = {
  answers: PropTypes.array.isRequired,
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
