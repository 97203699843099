import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import { Input, Switch } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { useViewAnswerStyles } from './viewAnswer.styles';

export function ViewAnswer({ answer, onChange, onDelete, disabled }) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useViewAnswerStyles();

  const handleChange = fieldName => event => {
    onChange(fieldName, { answerId: answer.id })(event);
  };

  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        <Grid item lg={9} md={9} sm={8} xs={8}>
          <Input
            isLoading={isSkeletonLoading(answer.name)}
            required
            disabled={disabled}
            name={t('quizQuestionAnswers.properties.name')}
            label={t('quizQuestionAnswers.properties.name')}
            variant="outlined"
            value={answer.name || ''}
            onChange={handleChange('name')}
            inputProps={{ 'aria-label': t('quizQuestionAnswers.properties.name') }}
            validators={['required', 'maxStringLength:256']}
            errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 256 })]}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <Switch
            label={t('quizQuestionAnswers.properties.isCorrect')}
            checked={answer.isCorrect || false}
            isLoading={isSkeletonLoading(answer.isCorrect)}
            onChange={handleChange('isCorrect')}
            disabled={disabled}
          />
        </Grid>
        <Grid item lg={1} md={1} sm={2} xs={2} className={classes.actionsContainer}>
          <Tooltip title={t('actions.remove')}>
            <IconButton
              disabled={disabled}
              onClick={evt => {
                evt.stopPropagation();
                onDelete(answer);
              }}
              aria-label={t('actions.remove')}
              style={{ fontSize: '0.5rem' }}
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}

ViewAnswer.propTypes = {
  answer: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isCorrect: PropTypes.bool
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
