import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { CustomHtmlEditor } from 'shared';
import { viewCustomPagesConfig } from './viewCustomPages.config';
import { ListCustomPages } from './List';
import { useCustomPagesStyles } from './viewCustomPages.styles';
import { NAME_SPACE } from 'i18n';
import { useTranslation } from 'react-i18next';
import { execConfirmFormModal, execConfirmModal } from 'shared';
import { FormSelectHtmlTemplate } from 'pages/shared';
import { UserContext, PLAN_ACCESS } from 'auth';

export const ViewCustomPages = ({
  customPages,
  mediaFiles,
  readToken,
  onAddPage,
  onChange,
  onDeletePage,
  errorMessage,
  successMessage,
  resetErrorAndSuccessMessages
}) => {
  const [selectedPageId, setSelectedPageId] = useState();
  const classes = useCustomPagesStyles();
  const { t } = useTranslation(NAME_SPACE.HTML_TEMPLATES);

  const { hasMinimumPlan } = useContext(UserContext);
  const isInPlan = hasMinimumPlan(PLAN_ACCESS.STANDARD);
  const showRatingComponents = hasMinimumPlan(PLAN_ACCESS.ADVANCED);
  const showAuthComponents = hasMinimumPlan(PLAN_ACCESS.STANDARD);
  const showQuizComponents = hasMinimumPlan(PLAN_ACCESS.ADVANCED);

  const configurationByPlan = useMemo(() => {
    let config = { ...viewCustomPagesConfig };
    if (config && config.pluginsOpts['experiences-plugin']) {
      config.pluginsOpts['experiences-plugin'].blocks = [
        ...['mainContainer', 'pageReference', 'cookiesButton', 'customForm', 'shareContainer', 'shareButton'],
        ...(showRatingComponents ? ['ratingsModalButton', 'ratingsShareableFields'] : []),
        ...(showAuthComponents ? ['authenticationControls'] : []),
        ...(showQuizComponents ? ['quizForm'] : [])
      ];
    }
    return config;
  }, [showRatingComponents, showAuthComponents, showQuizComponents]);

  const handleSelectPage = pageId => {
    setSelectedPageId(pageId);
  };

  useEffect(() => {
    if (!selectedPageId && customPages && customPages.length > 0) {
      setSelectedPageId(customPages[0].id);
    }
  }, [customPages, selectedPageId]);

  const editorMediaFiles = useMemo(() => {
    return mediaFiles.map(f => ({ name: f.tag, src: `${f.blobUrl}?${readToken}` }));
  }, [mediaFiles, readToken]);

  const saveCommandEventHandler = {
    name: 'run:save-command',
    action: model => {
      if (onChange) {
        const cssContent = model.css ? `<style>${model.css}</style>` : '';
        const newEvent = {
          target: {
            name: 'custom-page',
            value: model.html + cssContent
          }
        };
        onChange('content', { pageId: model.pageId })(newEvent);
      }
    }
  };

  const openTemplatesCommandEventHandler = {
    name: 'run:show-templates',
    action: async editor => {
      let result;
      if (isInPlan) {
        result = await execConfirmFormModal({
          title: t('htmlTemplates:templateConfirm.title'),
          description: t('htmlTemplates:templateConfirm.description'),
          confirmText: t('common:actions.accept'),
          declineText: t('common:actions.close'),
          confirmClassName: classes.buttonAdd,
          declineClassName: classes.buttonDelete,
          formComponent: <FormSelectHtmlTemplate />
        });
      } else {
        await execConfirmModal({
          title: t('htmlTemplates:name'),
          description: t('common:permissions.forbiddenByPlan'),
          declineText: t('common:actions.close'),
          declineClassName: classes.buttonDelete,
          showConfirm: false
        });
      }

      if (result) {
        if (onChange) {
          const newEvent = {
            target: {
              name: 'custom-page',
              value: result.content
            }
          };
          onChange('content', { pageId: selectedPageId })(newEvent);
        }
        editor.Commands.run('select-template', { content: result.content });
      }
    }
  };

  return (
    <>
      <div className={classes.customPages}>
        <Grid container>
          <Grid item xs={12} sm={5} md={3} lg={2}>
            <ListCustomPages
              customPages={customPages}
              onSelectPage={handleSelectPage}
              selectedPageId={selectedPageId}
              onAddPage={onAddPage}
              onDeletePage={onDeletePage}
              successMessage={successMessage}
              errorMessage={errorMessage}
              resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
            />
          </Grid>
          <Grid item xs={12} sm={7} md={9} lg={10} style={{ height: '720px', maxHeight: '720px' }}>
            <CustomHtmlEditor
              pages={[...customPages]}
              selectedPageId={selectedPageId}
              assets={editorMediaFiles}
              eventHandlers={[saveCommandEventHandler, openTemplatesCommandEventHandler]}
              customConfiguration={configurationByPlan}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

ViewCustomPages.propTypes = {
  customPages: PropTypes.array,
  mediaFiles: PropTypes.array,
  readToken: PropTypes.string,
  onAddPage: PropTypes.func,
  onChange: PropTypes.func,
  onDeletePage: PropTypes.func,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  resetErrorAndSuccessMessages: PropTypes.func
};
