export const quizWinnerForm = editor => {
  editor.BlockManager.add('quiz-winner-form', {
    category: 'Experience Utils',
    label: 'Quiz Winner Form',
    attributes: { class: 'fa fa-question-circle' },
    content: {
      type: 'quiz-winner-form',
      classes: ['quiz-winner-form'],
      attributes: { name: 'quiz-winner-form' },
      components: [
        {
          components: [
            {
              type: 'label',
              classes: ['quiz-winner-label'],
              attributes: { for: 'winnerName' },
              components: 'Name'
            },
            {
              type: 'input',
              attributes: {
                type: 'text',
                required: '',
                name: 'winnerName',
                pattern: '.{2,100}'
              },
              classes: ['quiz-winner-input']
            },
            {
              type: 'label',
              classes: ['quiz-winner-label'],
              attributes: { for: 'winnerEMail' },
              components: 'E-Mail'
            },
            {
              type: 'input',
              attributes: {
                type: 'email',
                required: '',
                name: 'winnerEMail',
                pattern: '.{2,100}'
              },
              classes: ['quiz-winner-input']
            }
          ]
        },
        {
          components: [
            {
              type: 'page-reference',
              attributes: {
                'data-target': '',
                'data-type': 'submit'
              },
              components: [{ type: 'text', components: 'Submit' }]
            }
          ]
        }
      ]
    }
  });

  editor.DomComponents.addType('quiz-winner-form', {
    isComponent: el => el.tagName === 'FORM' && el.className === 'quiz-winner-form',

    model: {
      defaults: {
        tagName: 'form',
        droppable: ':not(form)',
        draggable: ':not(form)'
      }
    },
    traits: [],
    view: {
      events: {
        // The submit of the form might redirect the user from the editor so
        // we should always prevent the default here.
        submit: e => e.preventDefault()
      }
    }
  });
};
