import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFormState } from 'shared';

export function useQuestionForm(question) {
  const [questionInputs, setQuestionInputs] = useFormState(question);

  const handleChangeAnswerItem = (fieldName, answerId, target) => {
    let forcedFields = {};
    if (fieldName === 'isCorrect') {
      forcedFields.isCorrect = false;
    }
    setQuestionInputs(prevInputs => ({
      ...prevInputs,
      answers: (prevInputs.answers || []).map(p => {
        return p.id === answerId
          ? { ...p, [fieldName]: target.type === 'checkbox' ? !p[fieldName] : target.value }
          : { ...p, ...forcedFields };
      })
    }));
  };

  const handleChange =
    (fieldName, modelTarget) =>
    ({ target }) => {
      if (!modelTarget) {
        setQuestionInputs(inputs => ({ ...inputs, [fieldName]: target.value }));
      } else if (modelTarget['answerId']) {
        handleChangeAnswerItem(fieldName, modelTarget['answerId'], target);
      }
    };

  const handleDeleteAnswer = useCallback(
    answerToDelete => {
      setQuestionInputs(prev => {
        return { ...prev, answers: (prev.answers || []).filter(answer => answer.id !== answerToDelete.id) };
      });
    },
    [setQuestionInputs]
  );

  const handleAddAnswer = useCallback(async () => {
    setQuestionInputs(prevInputs => ({
      ...prevInputs,
      answers: [
        ...(prevInputs.answers || []),
        {
          id: uuidv4(),
          isCorrect: false
        }
      ]
    }));
  }, [setQuestionInputs]);

  return {
    questionInputs,
    setQuestionInputs,
    handleChange,
    handleAddAnswer,
    handleDeleteAnswer
  };
}
