import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { Input, Switch } from '@engloba-tech/englobity';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading, LocaleDatePicker } from 'shared';
import { useViewQuizStyles } from './viewQuiz.styles';
import { ViewQuizQuestions } from './ViewQuizQuestions';
import { ContentPageSelect } from '../shared';

export function ViewQuiz({
  experience,
  onChange,
  onSwitchChange,
  onEditQuizQuestion,
  onDeleteQuizQuestion,
  canBeDeleted,
  errorMessage,
  successMessage,
  resetErrorAndSuccessMessages
}) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useViewQuizStyles();

  const requiredValidators = useMemo(() => {
    if (experience.quizStartDate || experience.quizEndDate) {
      return ['required'];
    } else {
      return [];
    }
  }, [experience.quizStartDate, experience.quizEndDate]);

  return (
    <div className={classes.sectionBlock}>
      <Grid container spacing={3} item xs={12} className={classes.container}>
        <Grid item xs={12} className={classes.titleContainer}>
          <Typography variant="h6" className={classes.title}>
            {t('quizQuestions.mainDataSection')}
          </Typography>
        </Grid>
        <Grid item xs={12} container spacing={4} className={classes.contentContainer}>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={3} sm={3} md={6}>
                <LocaleDatePicker
                  isLoading={isSkeletonLoading(experience.quizStartDate)}
                  icon={<DateRangeIcon />}
                  name={t('properties.quizStartDate')}
                  label={t('properties.quizStartDate')}
                  value={experience.quizStartDate}
                  onChange={onChange('quizStartDate')}
                  inputProps={{ 'aria-label': 'quizStartDate' }}
                  required={requiredValidators?.length > 0}
                  validators={requiredValidators}
                  errorMessages={[t('validations.required')]}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={6}>
                <LocaleDatePicker
                  isLoading={isSkeletonLoading(experience.quizEndDate)}
                  icon={<DateRangeIcon />}
                  name={t('properties.quizEndDate')}
                  label={t('properties.quizEndDate')}
                  value={experience.quizEndDate}
                  onChange={onChange('quizEndDate')}
                  inputProps={{ 'aria-label': 'quizEndDate' }}
                  required={requiredValidators?.length > 0}
                  validators={requiredValidators}
                  errorMessages={[t('validations.required')]}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={6}>
                <Input
                  isLoading={isSkeletonLoading(experience.quizNumberOfWinners)}
                  type="number"
                  name={t('properties.quizNumberOfWinners')}
                  label={t('properties.quizNumberOfWinners')}
                  variant="outlined"
                  value={experience.quizNumberOfWinners || ''}
                  fullWidth
                  onChange={onChange('quizNumberOfWinners')}
                  inputProps={{ 'aria-label': t('properties.quizNumberOfWinners') }}
                  required={requiredValidators?.length > 0}
                  validators={['minNumber:1', ...requiredValidators]}
                  errorMessages={[t('validations.minNumber', { minimum: 1 }), t('validations.required')]}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={6}>
                <Input
                  isLoading={isSkeletonLoading(experience.quizWinnerInterval)}
                  type="number"
                  name={t('properties.quizWinnerInterval')}
                  label={t('properties.quizWinnerInterval')}
                  variant="outlined"
                  value={experience.quizWinnerInterval || ''}
                  fullWidth
                  onChange={onChange('quizWinnerInterval')}
                  inputProps={{ 'aria-label': t('properties.quizWinnerInterval') }}
                  required={requiredValidators?.length > 0}
                  validators={['minNumber:1', ...requiredValidators]}
                  errorMessages={[t('validations.minNumber', { minimum: 1 }), t('validations.required')]}
                />
              </Grid>

              <Grid item xs={3} sm={3} md={6}>
                <ContentPageSelect
                  variant="outlined"
                  withAnyLabel
                  label={t('properties.quizClosedPageReference')}
                  value={experience.quizClosedPageReference || ''}
                  onChange={onChange('quizClosedPageReference')}
                  options={experience.pages}
                  fullWidth
                  className={classes.select}
                  required={requiredValidators?.length > 0}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={6}>
                <ContentPageSelect
                  variant="outlined"
                  withAnyLabel
                  label={t('properties.quizWrongPageReference')}
                  value={experience.quizWrongPageReference || ''}
                  onChange={onChange('quizWrongPageReference')}
                  options={experience.pages}
                  fullWidth
                  className={classes.select}
                  required={requiredValidators?.length > 0}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={6}>
                <ContentPageSelect
                  variant="outlined"
                  withAnyLabel
                  label={t('properties.quizCorrectPageReference')}
                  value={experience.quizCorrectPageReference || ''}
                  onChange={onChange('quizCorrectPageReference')}
                  options={experience.pages}
                  fullWidth
                  className={classes.select}
                  required={requiredValidators?.length > 0}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={6}>
                <ContentPageSelect
                  variant="outlined"
                  withAnyLabel
                  label={t('properties.quizNoWinnerPageReference')}
                  value={experience.quizNoWinnerPageReference || ''}
                  onChange={onChange('quizNoWinnerPageReference')}
                  options={experience.pages}
                  fullWidth
                  className={classes.select}
                  required={requiredValidators?.length > 0}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={6}>
                <ContentPageSelect
                  variant="outlined"
                  withAnyLabel
                  label={t('properties.quizWinnerPageReference')}
                  value={experience.quizWinnerPageReference || ''}
                  onChange={onChange('quizWinnerPageReference')}
                  options={experience.pages}
                  fullWidth
                  className={classes.select}
                  required={requiredValidators?.length > 0}
                />
              </Grid>
              <Grid item xs={6} sm={3} md={6}>
                <Switch
                  label={t('properties.quizWinnerRepetitionAllowed')}
                  checked={experience.quizWinnerRepetitionAllowed || false}
                  isLoading={isSkeletonLoading(experience.quizWinnerRepetitionAllowed)}
                  onChange={onSwitchChange('quizWinnerRepetitionAllowed')}
                  className={classes.switch}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.rightColumnContainer}>
            <Grid container spacing={3} className={classes.rightContentContainer}>
              <Grid item xs={12} style={{ flex: 1 }}>
                <Input
                  isLoading={isSkeletonLoading(experience.quizWinnerMailSubject)}
                  name={t('properties.quizWinnerMailSubject')}
                  label={t('properties.quizWinnerMailSubject')}
                  variant="outlined"
                  value={experience.quizWinnerMailSubject || ''}
                  onChange={onChange('quizWinnerMailSubject')}
                  inputProps={{ 'aria-label': t('properties.quizWinnerMailSubject') }}
                  required={requiredValidators?.length > 0}
                  validators={['maxStringLength:100', ...requiredValidators]}
                  errorMessages={[t('common:validations.maxStringLength', { length: 100 }), t('validations.required')]}
                />
              </Grid>
              <Grid item xs={12} style={{ height: '100%' }}>
                <Input
                  isLoading={isSkeletonLoading(experience.quizWinnerMailBody)}
                  name={t('properties.quizWinnerMailBody')}
                  label={t('properties.quizWinnerMailBody')}
                  value={experience.quizWinnerMailBody || ''}
                  variant="outlined"
                  multiline
                  fullWidth
                  className={classes.textarea}
                  rows={3}
                  onChange={onChange('quizWinnerMailBody')}
                  inputProps={{ 'aria-label': t('properties.quizWinnerMailBody') }}
                  onKeyPress={e => {
                    if (e.which === 13) {
                      e.stopPropagation();
                    }
                  }}
                  required={requiredValidators?.length > 0}
                  validators={['maxStringLength:4000', ...requiredValidators]}
                  errorMessages={[t('common:validations.maxStringLength', { length: 4000 }), t('validations.required')]}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ViewQuizQuestions
        quizQuestions={experience.quizQuestions}
        pages={experience.pages}
        onEditQuizQuestion={onEditQuizQuestion}
        onDeleteQuizQuestion={onDeleteQuizQuestion}
        canBeDeleted={canBeDeleted}
        errorMessage={errorMessage}
        successMessage={successMessage}
        resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
      />
    </div>
  );
}

ViewQuiz.propTypes = {
  experience: PropTypes.shape({
    id: PropTypes.string,
    quizStartDate: PropTypes.string,
    quizEndDate: PropTypes.string,
    quizNumberOfWinners: PropTypes.number,
    quizWinnerInterval: PropTypes.number,
    quizWinnerMailSubject: PropTypes.string,
    quizWinnerMailBody: PropTypes.string,
    quizClosedPageReference: PropTypes.string,
    quizNoWinnerPageReference: PropTypes.string,
    quizWinnerPageReference: PropTypes.string,
    quizWrongPageReference: PropTypes.string,
    quizCorrectPageReference: PropTypes.string,
    quizWinnerRepetitionAllowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    quizQuestions: PropTypes.array,
    pages: PropTypes.array
  }),
  canBeDeleted: PropTypes.bool,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  resetErrorAndSuccessMessages: PropTypes.func,
  onChange: PropTypes.func,
  onSwitchChange: PropTypes.func,
  onEditQuizQuestion: PropTypes.func,
  onDeleteQuizQuestion: PropTypes.func
};
