import grapesjs from 'grapesjs';
import { cookiesButton } from './cookiesButton';
import { mainContainer } from './mainContainer';
import { pageReference } from './pageReference';
import { ratingsModalButton } from './ratingsModalButton';
import { ageRangeSelect } from './ageRange';
import { customForm } from './customForm';
import { quizForm } from './quizForm';
import { genderSelect } from './genderSelect';
import { shareButton } from './shareButton';
import { shareContainer } from './shareContainer';
import { ratingsShareableFields } from './ratingsShareableFields';
import { authenticationControls } from './authenticationControls';
import { quizWinnerForm } from './quizWinnerForm';

export default grapesjs.plugins.add('experiences-plugin', (editor, config = {}) => {
  let defaults = {
    blocks: ['mainContainer', 'pageReference', 'cookiesButton', 'shareContainer', 'shareButton']
  };

  const opts = { ...defaults, ...config };
  const toAdd = name => opts.blocks.indexOf(name) >= 0;

  if (toAdd('mainContainer')) {
    mainContainer(editor);
  }
  if (toAdd('pageReference')) {
    pageReference(editor);
  }
  if (toAdd('cookiesButton')) {
    cookiesButton(editor);
  }
  if (toAdd('ratingsModalButton')) {
    ratingsModalButton(editor);
  }
  if (toAdd('ratingsShareableFields')) {
    ratingsShareableFields(editor);
  }

  if (toAdd('customForm')) {
    ageRangeSelect(editor, false);
    genderSelect(editor, false);
    customForm(editor);
  } else {
    if (toAdd('ageRangeSelect')) {
      ageRangeSelect(editor);
    }
    if (toAdd('genderSelect')) {
      genderSelect(editor);
    }
  }

  if (toAdd('shareContainer')) {
    shareContainer(editor);
  }

  if (toAdd('shareButton')) {
    shareButton(editor);
  }

  if (toAdd('authenticationControls')) {
    authenticationControls(editor);
  }

  if (toAdd('quizForm')) {
    quizForm(editor);
    quizWinnerForm(editor);
  }
});
