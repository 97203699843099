import { useMemo } from 'react';
import { settings, KEY_SETTINGS } from 'settings';
import { isSkeletonLoading } from 'shared';
import { FILTER_TYPE } from 'shared/ViewTable/AdvancedFilters';

export function useQRCodesBuildTable(inputs, t) {
  const headCells = useMemo(() => {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: t('properties.name')
      },
      {
        id: 'product',
        numeric: false,
        disablePadding: true,
        label: t('properties.product')
      },
      {
        id: 'customUrl',
        numeric: false,
        disablePadding: false,
        label: t('properties.customUrl'),
        isSortable: false,
        filterType: FILTER_TYPE.NONE
      },
      {
        id: 'scans',
        numeric: false,
        disablePadding: false,
        label: t('properties.scans'),
        isSortable: false,
        filterType: FILTER_TYPE.NONE
      }
    ];
  }, [t]);

  const rows = useMemo(() => {
    if (!inputs) {
      return [];
    }

    const experienceAppUrl = settings.getSetting(KEY_SETTINGS.EXPERIENCE_APP_URL);

    return inputs.items.map(qrCode => ({
      name: qrCode.name,
      product: qrCode.product,
      customUrl: isSkeletonLoading(qrCode.id) ? qrCode.id : `${experienceAppUrl || ''}?productId=${qrCode.id}`,
      scans: qrCode.scans,
      id: qrCode.id,
      canBeDeleted:
        (qrCode.scans === 0 || !qrCode.isProductPublished) && !qrCode.hasRatedScans && !qrCode.hasQuizAttempts
    }));
  }, [inputs]);

  return { headCells, rows };
}
