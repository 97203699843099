import React, { useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import ViewQuiltOutlinedIcon from '@material-ui/icons/ViewQuiltOutlined';
import CollectionsIcon from '@material-ui/icons/CollectionsOutlined';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import LanguageIcon from '@material-ui/icons/Language';
import SearchIcon from '@material-ui/icons/Search';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import StarIcon from '@material-ui/icons/Star';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { Form, Tabs, AsyncInputAutocomplete, Input, Switch } from '@engloba-tech/englobity';
import { isSkeletonLoading } from 'shared';
import { templates, languages } from 'services';
import { NAME_SPACE } from 'i18n';
import { settings, KEY_SETTINGS } from 'settings';
import { experienceHelper, TEMPLATE_PAGE_REFERENCE_TYPE, TEMPLATE_NAME } from '../shared';
import { useFormExperienceStyles } from './formExperience.styles';
import { useExperienceForm } from './useExperienceForm';
import { ViewFields } from '../ViewFields';
import { ViewExperience } from '../ViewExperience';
import { ViewLegalBlock } from '../ViewLegalBlock';
import { ViewMediaFiles } from '../ViewMediaFiles';
import { ViewCustomPages } from '../ViewCustomPages';
import { ViewHistory } from '../ViewHistory';
import { ViewArticles } from '../ViewArticles';
import { ViewQuiz } from '../ViewQuiz';
import { UserContext } from 'auth';
import { PLAN_ACCESS } from 'auth';
import { UnauthorizedSection } from 'pages/Unauthorized';

export function FormExperience({ formRef, onSubmit, experience, createMode, errors, onChangeInput, selectedTab }) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useFormExperienceStyles();
  const { hasMinimumPlan } = useContext(UserContext);
  const {
    experienceInputs,
    handleChange,
    handleSwitchChange,
    handleTemplateAutocomplete,
    handleEditField,
    handleDeleteFields,
    handleAddPage,
    handleDeletePage,
    handleAddMediaFiles,
    handleEditMediaFile,
    handleDeleteMediaFile,
    handleAddIcon,
    handleDeleteIcon,
    handleEditArticle,
    handleDeleteArticle,
    handleEditQuizQuestion,
    handleDeleteQuizQuestion,
    handleLanguageAutocomplete,
    successMessage,
    errorMessage,
    resetErrorAndSuccessMessages
  } = useExperienceForm(experience, createMode, onChangeInput, hasMinimumPlan);

  function handleSubmit() {
    const storageUrl = settings.getSetting(KEY_SETTINGS.STORAGE_URL);
    const regex = new RegExp(`(?!${storageUrl}.*?)(\\?sv.*?(?=[\\"']))`, 'gim');
    onSubmit({
      ...experienceInputs,
      pages: experienceInputs.pages.map(p => ({
        ...p,
        content: p.content ? p.content.replaceAll(regex, '#TOKEN#') : p.content
      }))
    });
  }

  const renderTabs = useMemo(() => {
    const sectionsUsed = experienceHelper.templateSectionsUsed(experienceInputs?.template);
    const hasLegalBlockAccess = hasMinimumPlan(PLAN_ACCESS.ADVANCED);
    const hasRatingsAccess = hasMinimumPlan(PLAN_ACCESS.ADVANCED);
    const hasQuizQuestionsAccess = hasMinimumPlan(PLAN_ACCESS.ADVANCED);

    let tabs = [
      {
        name: t('experienceData'),
        icon: <WidgetsOutlinedIcon />,
        children: (
          <ViewExperience
            experience={experienceInputs}
            onChange={handleChange}
            onSwitchChange={handleSwitchChange}
            onDeleteIcon={handleDeleteIcon}
            onAddIcon={handleAddIcon}
            successMessage={successMessage}
            errorMessage={errorMessage}
            resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
          />
        )
      },
      ...(sectionsUsed.mediaFiles
        ? [
            {
              name: t('mediaFiles.name'),
              icon: <CollectionsIcon />,
              children: (
                <ViewMediaFiles
                  experience={experienceInputs}
                  onAddMediaFiles={handleAddMediaFiles}
                  onEditMediaFile={handleEditMediaFile}
                  onDeleteMediaFile={handleDeleteMediaFile}
                  successMessage={successMessage}
                  errorMessage={errorMessage}
                  resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
                />
              )
            }
          ]
        : []),
      ...(sectionsUsed.editor
        ? [
            {
              name: t('htmlEditor'),
              icon: <ViewQuiltOutlinedIcon />,
              children: (
                <ViewCustomPages
                  experience={experienceInputs}
                  customPages={experienceInputs.pages.filter(
                    page =>
                      page.reference === TEMPLATE_PAGE_REFERENCE_TYPE.DEFAULT_PAGE ||
                      (page.reference === TEMPLATE_PAGE_REFERENCE_TYPE.AUTHENTICATION &&
                        hasMinimumPlan(PLAN_ACCESS.STANDARD))
                  )}
                  mediaFiles={experienceInputs.mediaFiles}
                  readToken={experienceInputs.readToken}
                  onChange={handleChange}
                  onAddPage={handleAddPage}
                  onDeletePage={handleDeletePage}
                  successMessage={successMessage}
                  errorMessage={errorMessage}
                  resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
                />
              )
            }
          ]
        : []),
      ...(sectionsUsed.productRatings
        ? [
            {
              name: t('articleRatings.name'),
              icon: <StarIcon />,
              children: hasRatingsAccess ? (
                <ViewArticles
                  articles={experienceInputs.articles || []}
                  mediaFiles={experienceInputs.mediaFiles}
                  onEditArticle={handleEditArticle}
                  onDeleteArticle={handleDeleteArticle}
                  canBeDeleted={!experienceInputs.hasScans}
                  successMessage={successMessage}
                  errorMessage={errorMessage}
                  resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
                />
              ) : (
                <UnauthorizedSection messageKey={'permissions.forbiddenByPlan'} />
              )
            }
          ]
        : []),
      ...(sectionsUsed.quizQuestions
        ? [
            {
              name: t('quizQuestions.name'),
              icon: <ContactSupportIcon />,
              children: hasQuizQuestionsAccess ? (
                <ViewQuiz
                  experience={experienceInputs}
                  onChange={handleChange}
                  onSwitchChange={handleSwitchChange}
                  onEditQuizQuestion={handleEditQuizQuestion}
                  onDeleteQuizQuestion={handleDeleteQuizQuestion}
                  canBeDeleted
                  successMessage={successMessage}
                  errorMessage={errorMessage}
                  resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
                />
              ) : (
                <UnauthorizedSection messageKey={'permissions.forbiddenByPlan'} />
              )
            }
          ]
        : []),
      ...(sectionsUsed.fields
        ? [
            {
              name: t('fields.name'),
              icon: <ListIcon />,
              children: (
                <ViewFields
                  fields={experienceInputs.fields}
                  onEditField={handleEditField}
                  onDeleteFields={handleDeleteFields}
                  successMessage={successMessage}
                  errorMessage={errorMessage}
                  resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
                />
              )
            }
          ]
        : []),
      {
        name: t('legalBlock.name'),
        icon: <LanguageIcon />,
        children: hasLegalBlockAccess ? (
          <ViewLegalBlock
            experience={experienceInputs}
            mediaFiles={experienceInputs.mediaFiles}
            onSwitchChange={handleSwitchChange}
            onChange={handleChange}
          />
        ) : (
          <UnauthorizedSection messageKey={'permissions.forbiddenByPlan'} />
        )
      },
      {
        name: t('history.name'),
        icon: <SupervisedUserCircleIcon />,
        children: <ViewHistory experience={experienceInputs} />
      }
    ];

    return tabs;
  }, [
    t,
    experienceInputs,
    handleChange,
    handleSwitchChange,
    handleEditField,
    handleDeleteFields,
    handleAddPage,
    handleDeletePage,
    handleAddMediaFiles,
    handleEditMediaFile,
    handleDeleteMediaFile,
    handleAddIcon,
    handleDeleteIcon,
    handleEditArticle,
    handleDeleteArticle,
    handleEditQuizQuestion,
    handleDeleteQuizQuestion,
    successMessage,
    errorMessage,
    resetErrorAndSuccessMessages,
    hasMinimumPlan
  ]);

  return (
    <>
      <Form
        className={classes.formExperience}
        errors={errors}
        elementRef={formRef}
        onSubmit={handleSubmit}
        autoComplete="off"
        onKeyPress={e => {
          if (e.which === 13) {
            if (e?.target?.className?.indexOf('gjs-trait') < 0) {
              e.preventDefault();
            }
            e.stopPropagation();
          }
        }}
      >
        <Grid container className={`${classes.header} ${classes.background} ${classes.containers}`}>
          <Grid item container spacing={3}>
            <Grid item xs={6}>
              <Input
                isLoading={isSkeletonLoading(experienceInputs.name)}
                required
                name={t('properties.name')}
                label={t('properties.name')}
                variant="outlined"
                value={experienceInputs.name || ''}
                onChange={handleChange('name')}
                inputProps={{ 'aria-label': t('properties.name') }}
                validators={['required', 'maxStringLength:255']}
                errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 255 })]}
              />
            </Grid>
            <Grid item xs={6}>
              <AsyncInputAutocomplete
                isLoading={isSkeletonLoading({
                  name: experienceInputs.template,
                  id: experienceInputs.templateId
                })}
                onChange={(e, element) => handleTemplateAutocomplete(element)}
                label={t('properties.template')}
                required
                variant="outlined"
                icon={<SearchIcon />}
                value={createMode ? null : { name: experienceInputs?.template?.name, id: experienceInputs.templateId }}
                defaultInputValue={experienceInputs.template?.name}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={option => option.name}
                requestAction={templates.getUsingQueryFilter}
                validators={['required']}
                errorMessages={[t('validations.required')]}
              />
            </Grid>
            <Grid item xs={6}>
              <AsyncInputAutocomplete
                isLoading={isSkeletonLoading({
                  name: experienceInputs.language,
                  id: experienceInputs.languageId
                })}
                onChange={(e, element) => handleLanguageAutocomplete(element)}
                label={t('properties.language')}
                required
                variant="outlined"
                icon={<SearchIcon />}
                value={createMode ? null : { name: experienceInputs?.language?.name, id: experienceInputs.languageId }}
                defaultInputValue={experienceInputs.language?.name}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={option => option.name}
                requestAction={languages.getUsingQueryFilter}
                validators={['required']}
                errorMessages={[t('validations.required')]}
              />
            </Grid>
            <Grid item xs={3}>
              <Switch
                label={t('properties.isPublished')}
                checked={experienceInputs.isPublished || false}
                onChange={handleSwitchChange('isPublished')}
                className={classes.switch}
                isLoading={isSkeletonLoading(experience.isPublished)}
              />
            </Grid>
            {experienceInputs.template?.name === TEMPLATE_NAME.REDIRECT && (
              <Grid item xs={6}>
                <Input
                  isLoading={isSkeletonLoading(experienceInputs.redirectUrl)}
                  required
                  name={t('properties.redirectUrl')}
                  label={t('properties.redirectUrl')}
                  variant="outlined"
                  value={experienceInputs.redirectUrl || ''}
                  onChange={handleChange('redirectUrl')}
                  inputProps={{ 'aria-label': t('properties.redirectUrl') }}
                  validators={['required']}
                  errorMessages={[t('validations.required')]}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container className={`${classes.containers} ${classes.secondContainer}`}>
          <Grid item xs={12}>
            <Tabs classNameContent={classes.tabsMinHeight} tabs={renderTabs} defaultPosition={selectedTab} />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormExperience.propTypes = {
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  }),
  experience: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    templateId: PropTypes.string,
    template: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        name: PropTypes.string
      })
    ]),
    isPublished: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  }),
  createMode: PropTypes.bool.isRequired,
  onChangeInput: PropTypes.func,
  selectedTab: PropTypes.number
};
