import { makeStyles } from '@material-ui/core/styles';

export const useViewQuizStyles = makeStyles(theme => ({
  sectionBlock: {
    flexGrow: 1,
    padding: `${theme.spacing(4)}px ${theme.spacing(1)}px`,
    overflow: 'hidden'
  },
  container: {
    margin: 0,
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: theme.palette.primary.light
    }
  },
  titleContainer: {
    backgroundColor: '#fdfbf6',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.7rem 24px !important'
  },
  title: {
    fontWeight: theme.font.weight.bold,
    color: theme.palette.primary.main,
    lineHeight: 2.2,
    fontSize: '1.1rem'
  },
  contentContainer: {
    padding: `${theme.spacing(1)}px  !important`,
    backgroundColor: theme.palette.primary.light,
    margin: 0
  },
  rightColumnContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  rightContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flex: 1
  },
  select: {
    minWidth: '8rem',
    fontSize: 'inherit'
  },
  textarea: {
    height: '100%',
    '& .MuiFormControl-root': {
      height: '100%'
    },
    '& .MuiOutlinedInput-multiline': {
      height: '100%',
      padding: '20px 14px'
    },
    '& .MuiOutlinedInput-multiline textarea': {
      lineHeight: '1.5rem',
      height: '100%'
    }
  },
  switch: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginLeft: 0,
    marginRight: 0,
    '& .MuiFormControlLabel-label': {
      fontWeight: theme.font.weight.bold,
      fontSize: 'inherit'
    }
  }
}));
