import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DialogModal, useHandleOpen } from '@engloba-tech/englobity';
import { ViewTable, execConfirmModal, ButtonAdd, ButtonDelete } from 'shared';
import { NAME_SPACE } from 'i18n';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { useViewQuizQuestionsStyles } from './viewQuizQuestions.styles';
import { useQuizQuestionsBuildTable } from './useQuizQuestionsBuildTable';
import { FormQuestion } from './FormQuestion';

const questionEmpty = { answers: [] };

export const ViewQuizQuestions = ({
  quizQuestions,
  pages,
  onEditQuizQuestion,
  onDeleteQuizQuestion,
  canBeDeleted,
  errorMessage,
  successMessage,
  resetErrorAndSuccessMessages
}) => {
  const classes = useViewQuizQuestionsStyles();
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const { rows, headCells, getExperienceQuizQuestions } = useQuizQuestionsBuildTable(quizQuestions, t);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const { isOpen, handleClose, handleOpen } = useHandleOpen(false);
  const $formQuestionRef = useRef(null);

  function handleDetail([questionId]) {
    setSelectedQuestion(questionId ? quizQuestions.find(question => question.id === questionId) : { ...questionEmpty });
    handleOpen();
  }

  function handleModalAccept() {
    $formQuestionRef.current.submit();
  }

  function handleModalClose() {
    resetErrorAndSuccessMessages();
    handleClose();
  }

  function handleQuestionSubmit(question) {
    if (question) {
      onEditQuizQuestion(question);
    }
  }

  async function handleDelete(questionsToDelete) {
    try {
      await execConfirmModal({
        title: t('common:actions.remove'),
        description:
          questionsToDelete.length > 1
            ? t('quizQuestions.removeConfirm.message', { length: questionsToDelete.length })
            : t('quizQuestions.removeConfirm.singularMessage', {
                name: questionsToDelete[0].name
              }),
        confirmText: t('common:actions.accept'),
        declineText: t('common:actions.cancel'),
        confirmClassName: classes.buttonAdd,
        declineClassName: classes.buttonDelete
      });

      onDeleteQuizQuestion(questionsToDelete);
    } catch (err) {}
  }

  return (
    <>
      <ViewTable
        rows={rows}
        cells={headCells}
        mainKey={'name'}
        defaultOrderBy={{ property: 'name', direction: 'asc' }}
        onFetchData={getExperienceQuizQuestions}
        selectRowWhen={() => canBeDeleted}
        emptyText={t('quizQuestions.empty')}
        actions={[
          {
            title: t('actions.view'),
            name: 'view',
            properties: ['id'],
            label: t('common:edit'),
            cb: handleDetail,
            icon: <EditIcon />
          }
        ]}
        toolbar={{
          title: t('quizQuestions.name'),
          actions: [
            {
              condition: isAnySelected => !isAnySelected,
              cb: handleDetail,
              component: ButtonAdd
            },
            {
              condition: isAnySelected => isAnySelected,
              resetOnCB: true,
              cb: handleDelete,
              component: ButtonDelete
            }
          ]
        }}
      />

      <DialogModal
        className={classes.modal}
        maxWidth="md"
        fullWidth
        title={t('quizQuestions.dialogTitle')}
        description=""
        isOpen={isOpen}
        disableBackdropClick
        onClose={handleModalClose}
        buttons={[
          {
            children: <CloseIcon />,
            text: t('common:actions.cancel'),
            type: 'secondary',
            onClick: handleModalClose,
            className: classes.buttonDelete
          },
          {
            children: <DoneIcon />,
            text: t('common:actions.accept'),
            type: 'primary',
            onClick: handleModalAccept,
            className: classes.buttonAdd
          }
        ]}
      >
        <FormQuestion
          formRef={$formQuestionRef}
          onSubmit={handleQuestionSubmit}
          question={{ ...selectedQuestion }}
          successMessage={successMessage}
          errorMessage={errorMessage}
          resetErrorAndSuccessMessages={resetErrorAndSuccessMessages}
          onClose={handleModalClose}
          pages={pages}
        />
      </DialogModal>
    </>
  );
};

ViewQuizQuestions.propTypes = {
  quizQuestions: PropTypes.array.isRequired,
  pages: PropTypes.array,
  onEditQuizQuestion: PropTypes.func,
  onDeleteQuizQuestion: PropTypes.func,
  setErrorInfo: PropTypes.func,
  canBeDeleted: PropTypes.bool,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  resetErrorAndSuccessMessages: PropTypes.func
};
