import { SKELETON_KEY, SKELETON_KEY_BOOL, SKELETON_KEY_DATE, SKELETON_KEY_NUMBER } from './skeletonFactory';

export function skeletonField() {
  return {
    id: SKELETON_KEY,
    title: SKELETON_KEY,
    value: SKELETON_KEY,
    order: SKELETON_KEY_NUMBER
  };
}

export function skeletonIcon() {
  return {
    id: SKELETON_KEY,
    iconImageTag: SKELETON_KEY,
    isTopIcon: SKELETON_KEY_BOOL,
    order: SKELETON_KEY_NUMBER,
    targetImageTag: SKELETON_KEY,
    targetLink: SKELETON_KEY,
    targetType: SKELETON_KEY_NUMBER
  };
}

export function skeletonMediaFiles() {
  return {
    id: SKELETON_KEY,
    tag: SKELETON_KEY,
    targetLink: SKELETON_KEY,
    blobUrl: SKELETON_KEY
  };
}

export function skeletonPages() {
  return {
    id: SKELETON_KEY,
    backgroundColor: SKELETON_KEY,
    backgroundImageTag: SKELETON_KEY,
    content: SKELETON_KEY,
    contentPageReference: SKELETON_KEY,
    fontColor: SKELETON_KEY,
    isContentHomePage: SKELETON_KEY_BOOL,
    name: SKELETON_KEY,
    reference: SKELETON_KEY,
    visible: SKELETON_KEY,
    title: SKELETON_KEY,
    fontFamily: SKELETON_KEY,
    continueButtonText: SKELETON_KEY
  };
}

export function skeletonArticleRatingOption() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    reference: SKELETON_KEY
  };
}

export function skeletonArticleRating() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    reference: SKELETON_KEY,
    options: Array(1).fill(skeletonArticleRatingOption())
  };
}

export function skeletonArticle() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    articleRatings: Array(1).fill(skeletonArticleRating())
  };
}

export function skeletonQuizQuestionAnswer() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    isCorrect: SKELETON_KEY_BOOL
  };
}

export function skeletonQuizQuestion() {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    contentPageReference: SKELETON_KEY,
    answers: Array(1).fill(skeletonQuizQuestionAnswer())
  };
}

export function skeletonExperience(
  fieldsNum = 1,
  iconsNum = 2,
  mediaFilesNum = 1,
  pagesNum = 3,
  articlesNum = 2,
  quizQuestionsNum = 2
) {
  return {
    id: SKELETON_KEY,
    name: SKELETON_KEY,
    hasQRCodes: SKELETON_KEY_BOOL,
    hasTags: SKELETON_KEY_BOOL,
    templateId: SKELETON_KEY,
    template: SKELETON_KEY,
    languageId: SKELETON_KEY,
    language: SKELETON_KEY,
    cookiesEnabled: SKELETON_KEY_BOOL,
    legalAge: SKELETON_KEY_NUMBER,
    showTitle: SKELETON_KEY_BOOL,
    youtubeUrl: SKELETON_KEY,
    readToken: SKELETON_KEY,
    isPublished: SKELETON_KEY,
    ratingEmptyImageTag: SKELETON_KEY,
    ratingHalfImageTag: SKELETON_KEY,
    ratingFullImageTag: SKELETON_KEY,
    ratingCompletedResultType: SKELETON_KEY_NUMBER,
    ratingGratutideMessage: SKELETON_KEY,
    ratingShareTargetTemplatePageReference: SKELETON_KEY,
    ratingShareButtonTitle: SKELETON_KEY,
    isTamperType: SKELETON_KEY_BOOL,
    openTamperImageTag: SKELETON_KEY,
    openTamperText: SKELETON_KEY,
    closeTamperImageTag: SKELETON_KEY,
    closeTamperText: SKELETON_KEY,
    unknownTamperImageTag: SKELETON_KEY,
    unknownTamperText: SKELETON_KEY,
    successfulAuthenticationImageTag: SKELETON_KEY,
    successfulAuthenticationText: SKELETON_KEY,
    unsuccessfulAuthenticationImageTag: SKELETON_KEY,
    unsuccessfulAuthenticationText: SKELETON_KEY,
    experienceDefaultBackgroundColor: SKELETON_KEY,
    experienceDefaultBackgroundImage: SKELETON_KEY,
    experienceDefaultFontColor: SKELETON_KEY,
    experienceDefaultFontFamily: SKELETON_KEY,
    experienceDefaultImage: SKELETON_KEY,
    experienceDefaultMessage: SKELETON_KEY,
    quizStartDate: SKELETON_KEY_DATE,
    quizEndDate: SKELETON_KEY_DATE,
    quizNumberOfWinners: SKELETON_KEY_NUMBER,
    quizWinnerInterval: SKELETON_KEY_NUMBER,
    quizWinnerMailSubject: SKELETON_KEY,
    quizWinnerMailBody: SKELETON_KEY,
    quizWinnerRepetitionAllowed: SKELETON_KEY_BOOL,
    quizClosedPageReference: SKELETON_KEY,
    quizWrongPageReference: SKELETON_KEY,
    quizNoWinnerPageReference: SKELETON_KEY,
    quizWinnerPageReference: SKELETON_KEY,
    quizCorrectPageReference: SKELETON_KEY,
    fields: Array(fieldsNum).fill(skeletonField()),
    icons: Array(iconsNum).fill(skeletonIcon()),
    mediaFiles: Array(mediaFilesNum).fill(skeletonMediaFiles()),
    pages: Array(pagesNum).fill(skeletonPages()),
    articles: Array(articlesNum).fill(skeletonArticle()),
    quizQuestions: Array(quizQuestionsNum).fill(skeletonQuizQuestion())
  };
}
