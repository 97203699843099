export const quizForm = editor => {
  editor.BlockManager.add('quiz-form', {
    category: 'Experience Utils',
    label: 'Quiz Form',
    attributes: { class: 'fa fa-question-circle' },
    content: {
      type: 'quiz-form',
      classes: ['quiz-form'],
      attributes: { name: 'quiz-form' },
      components: [
        {
          type: 'Text',
          classes: ['quiz-question-title'],
          attributes: { 'data-quiz-question-title': '' },
          content: 'Question'
        },
        {
          classes: ['quiz-question-answer-container'],
          components: [
            {
              type: 'label',
              classes: ['quiz-question-answer'],
              attributes: { 'data-quiz-answer': '' },
              components: 'Answer option'
            }
          ]
        }
      ]
    }
  });

  editor.DomComponents.addType('quiz-form', {
    isComponent: el => el.tagName === 'FORM' && el.className === 'quiz-form',

    model: {
      defaults: {
        tagName: 'form',
        droppable: ':not(form)',
        draggable: ':not(form)'
      }
    },
    traits: [],
    view: {
      events: {
        // The submit of the form might redirect the user from the editor so
        // we should always prevent the default here.
        submit: e => e.preventDefault()
      }
    }
  });
};
