import { useState, useMemo, useCallback, useEffect } from 'react';
import { rowFilterHelper } from 'shared';

export const useQuizQuestionsBuildTable = (inputs, t) => {
  const [rows, setRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);

  const headCells = useMemo(() => {
    return [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: t('quizQuestions.properties.name'),
        ellipsis: true
      },
      {
        id: 'contentPageReference',
        numeric: false,
        disablePadding: true,
        label: t('quizQuestions.properties.contentPageReference'),
        ellipsis: true,
        isSortable: false
      }
    ];
  }, [t]);

  useEffect(() => {
    const rowsMapped = !inputs?.length
      ? []
      : inputs
      ? inputs.map(question => {
          return {
            id: question.id,
            name: question.name,
            contentPageReference: question.contentPageReference
          };
        })
      : [];
    setInitialRows(rowsMapped);
    setRows(rowsMapped);
  }, [inputs, inputs?.length]);

  const getExperienceQuizQuestions = useCallback(
    (paging, sorting, filters) => {
      setRows(rowFilterHelper.getFilteredRows(initialRows, filters));
    },
    [initialRows]
  );

  return { headCells, rows, getExperienceQuizQuestions };
};
