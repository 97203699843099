import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Input } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { TEMPLATE_PAGE_REFERENCE_TYPE } from '../experienceTypes.const';
import { useContentPageSelectStyles } from './contentPageSelect.styles';

export function ContentPageSelect({ value = '', withAnyLabel, error, errorMessage, required, options, ...props }) {
  const { t } = useTranslation(NAME_SPACE.COMMON);
  const classes = useContentPageSelectStyles();

  const elements = useMemo(() => {
    let el = [];
    if (withAnyLabel) el.push({ value: '', name: t('selectFirstOption') });

    el = el.concat(
      options
        .filter(page => page.reference === TEMPLATE_PAGE_REFERENCE_TYPE.DEFAULT_PAGE && !page.isContentHomePage)
        .map(page => ({ value: page.contentPageReference, name: page.contentPageReference }))
        .sort((a, b) => (a.value > b.value ? 1 : -1))
    );

    return el;
  }, [t, withAnyLabel, options]);

  return (
    <>
      <Input
        required={required}
        isLoading={isSkeletonLoading(value)}
        value={value?.toString()}
        select
        fullWidth
        validators={required && ['required']}
        errorMessages={required && [t('validations.required')]}
        {...props}
        className={classes.root}
      >
        {elements.map((element, index) => (
          <MenuItem key={index} value={element.value}>
            {element.name}
          </MenuItem>
        ))}
      </Input>
    </>
  );
}

ContentPageSelect.propTypes = {
  value: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  withAnyLabel: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string
};
