import { useEffect, useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFormState } from 'shared';
import { templates } from 'services';
import { experienceHelper, RATING_COMPLETED_RESULT_TYPE, TEMPLATE_NAME, TEMPLATE_PAGE_REFERENCE_TYPE } from '../shared';

export function useExperienceForm(experience, createMode, onChangeInput, hasMinimumPlan) {
  const [experienceInputs, setExperienceInputs] = useFormState(experience, onChangeInput);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    setExperienceInputs(
      {
        ...experience,
        pages: experience.pages.map(p => {
          return { ...p, content: p.content ? p.content.replaceAll('#TOKEN#', `?${experience.readToken}`) : null };
        })
      },
      false
    );
  }, [experience]);

  const handleChangePage = (fieldName, pageId, target) => {
    let forceDisabledCookies = false;
    if (fieldName === 'visible') {
      const page = experienceInputs.pages.filter(p => p.id === pageId)[0];
      forceDisabledCookies = page && page.reference === TEMPLATE_PAGE_REFERENCE_TYPE.LEGAL_TERMS && page.visible;
    }

    setExperienceInputs(prevInputs => ({
      ...prevInputs,
      cookiesEnabled: forceDisabledCookies ? false : prevInputs.cookiesEnabled,
      pages: prevInputs.pages.map(p => {
        return p.id === pageId ? { ...p, [fieldName]: target.type === 'checkbox' ? !p[fieldName] : target.value } : p;
      })
    }));
  };

  const handleChangeIcon = (fieldName, iconId, target) => {
    let forcedFields = {};
    if (fieldName === 'targetType') {
      forcedFields.targetLink = '';
      forcedFields.targetImageTag = '';
    }

    setExperienceInputs(prevInputs => ({
      ...prevInputs,
      icons: prevInputs.icons.map(p => {
        return p.id === iconId ? { ...p, ...forcedFields, [fieldName]: target.value } : p;
      })
    }));
  };

  const resetTamperFields = () => {
    return {
      openTamperImageTag: null,
      openTamperText: null,
      closeTamperImageTag: null,
      closeTamperText: null,
      unknownTamperImageTag: null,
      unknownTamperText: null
    };
  };

  const handleChange =
    (fieldName, modelTarget) =>
    ({ target }) => {
      if (!modelTarget) {
        let forcedFields = {};
        if (
          fieldName === 'ratingCompletedResultType' &&
          target.value !== RATING_COMPLETED_RESULT_TYPE.GRATITUDE_MESSAGE
        ) {
          forcedFields.ratingGratutideMessage = '';
        } else if (fieldName === 'ratingShareTargetTemplatePageReference' && !target.value) {
          forcedFields.ratingShareButtonTitle = '';
        }

        setExperienceInputs(inputs => ({ ...inputs, ...forcedFields, [fieldName]: target.value }));
      } else if (modelTarget['pageId']) {
        handleChangePage(fieldName, modelTarget['pageId'], target);
      } else if (modelTarget['iconId']) {
        handleChangeIcon(fieldName, modelTarget['iconId'], target);
      }
    };

  const handleSwitchChange =
    (fieldName, modelTarget) =>
    ({ target }) => {
      if (!modelTarget) {
        let forcedFields = {};
        if (fieldName === 'isTamperType' && !target.checked) {
          forcedFields = { ...resetTamperFields() };
        }
        setExperienceInputs(inputs => ({ ...inputs, ...forcedFields, [fieldName]: !inputs[fieldName] }));
      } else if (modelTarget['pageId']) {
        handleChangePage(fieldName, modelTarget['pageId'], target);
      }
    };

  async function handleTemplateAutocomplete(element) {
    if (element?.id) {
      const response = await templates.getById(element.id);
      if (response && response.data) {
        const newTemplate = { ...response.data };
        const sectionsUsed = experienceHelper.templateSectionsUsed(newTemplate);

        setExperienceInputs(prevInputs => ({
          ...prevInputs,
          template: newTemplate,
          templateId: element?.id,
          showTitle: false,
          redirectUrl: newTemplate.name === TEMPLATE_NAME.REDIRECT ? prevInputs.redirectUrl : '',
          authenticationAllowed:
            newTemplate.name === TEMPLATE_NAME.CIGAR_SHOP_ANDORRA ||
            !hasMinimumPlan(experienceHelper.getPageMinimumPlanVisible(TEMPLATE_PAGE_REFERENCE_TYPE.AUTHENTICATION))
              ? false
              : prevInputs.authenticationAllowed,
          experienceInfoAllowed: newTemplate.name === TEMPLATE_NAME.REDIRECT ? false : prevInputs.experienceInfoAllowed,
          ratingFullImageTag: null,
          ratingHalfImageTag: null,
          ratingEmptyImageTag: null,
          ratingCompletedResultType: null,
          ratingGratutideMessage: null,
          ratingShareButtonTitle: null,
          ratingShareTargetTemplatePageReference: null,
          isTamperType: null,
          openTamperImageTag: null,
          openTamperText: null,
          closeTamperImageTag: null,
          closeTamperText: null,
          unknownTamperImageTag: null,
          unknownTamperText: null,
          successfulAuthenticationImageTag: null,
          successfulAuthenticationText: null,
          unsuccessfulAuthenticationImageTag: null,
          unsuccessfulAuthenticationText: null,
          experienceDefaultBackgroundColor: null,
          experienceDefaultBackgroundImage: null,
          experienceDefaultFontColor: null,
          experienceDefaultFontFamily: null,
          experienceDefaultImage: null,
          experienceDefaultMessage: null,
          quizStartDate: null,
          quizEndDate: null,
          quizNumberOfWinners: null,
          quizWinnerInterval: null,
          quizWinnerMailSubject: null,
          quizWinnerMailBody: null,
          quizWinnerRepetitionAllowed: null,
          quizClosedPageReference: null,
          quizNoWinnerPageReference: null,
          quizWinnerPageReference: null,
          quizWrongPageReference: null,
          quizCorrectPageReference: null,
          pages: newTemplate.pages.map(templatePage => {
            return {
              id: uuidv4(),
              templatePageId: templatePage.id,
              visible: hasMinimumPlan(experienceHelper.getPageMinimumPlanVisible(templatePage.reference)),
              backgroundColor: '#000000',
              fontColor: '#FFFFFF',
              reference: templatePage.reference,
              name: templatePage.name,
              content: '',
              contentPageReference: templatePage.reference,
              isContentHomePage: templatePage.reference === TEMPLATE_PAGE_REFERENCE_TYPE.DEFAULT_PAGE,
              title: null,
              fontFamily: null
            };
          }),
          mediaFiles: sectionsUsed.mediaFiles
            ? [...experienceHelper.syncTemplateMediaFiles(prevInputs.mediaFiles, newTemplate)]
            : [],
          icons: sectionsUsed.icons ? prevInputs.icons : [],
          fields: sectionsUsed.fields ? prevInputs.fields : [],
          articles: sectionsUsed.productRatings ? prevInputs.articles : [],
          quizQuestions: sectionsUsed.quizQuestions ? prevInputs.quizQuestions : []
        }));
      }
    } else {
      setExperienceInputs(prevInputs => ({
        ...prevInputs,
        template: {},
        templateId: '',
        showTitle: false,
        pages: [],
        mediaFiles: [],
        icons: [],
        fields: [],
        articles: [],
        quizQuestions: []
      }));
    }
  }

  function handleLanguageAutocomplete(element) {
    if (element?.id) {
      setExperienceInputs(prevInputs => ({
        ...prevInputs,
        languageId: element?.id,
        language: { ...element }
      }));
    } else {
      setExperienceInputs(prevInputs => ({
        ...prevInputs,
        language: {},
        languageId: ''
      }));
    }
  }

  const handleEditField = useCallback(
    async field => {
      if (
        experienceInputs.fields.some(
          f => f.title?.trim().toLowerCase() === field.title?.trim().toLowerCase() && f.id !== field.id
        )
      ) {
        setErrorMessage('fields.validations.fieldAlreadyExists');
        return;
      }

      if (!field.id) {
        setExperienceInputs(prevInputs => ({
          ...prevInputs,
          fields: [...prevInputs.fields, { ...field, id: uuidv4() }]
        }));

        setSuccessMessage('fields.addedSuccessfully');
      } else {
        setExperienceInputs(prevInputs => ({
          ...prevInputs,
          fields: prevInputs.fields.map(f => {
            return f.id === field.id ? field : f;
          })
        }));
        setSuccessMessage('fields.updatedSuccessfully');
      }
    },
    [setExperienceInputs, experienceInputs.fields]
  );

  const handleDeleteFields = useCallback(
    fieldsToDelete => {
      setExperienceInputs(prev => {
        let fields = prev.fields;

        fieldsToDelete.forEach(fieldToDelete => {
          fields = fields.filter(f => f.id !== fieldToDelete.id);
        });

        return { ...prev, fields };
      });
    },
    [setExperienceInputs]
  );

  const handleAddPage = useCallback(
    async page => {
      if (
        experienceInputs.pages.some(
          p =>
            p.contentPageReference?.trim().toLowerCase() === page.contentPageReference?.trim().toLowerCase() &&
            p.id !== page.id
        )
      ) {
        setErrorMessage('pages.validations.pageAlreadyExists');
        return;
      }

      if (!page.id) {
        const mainPage = experienceInputs.pages.filter(p => p.isContentHomePage)[0];
        setExperienceInputs(prevInputs => ({
          ...prevInputs,
          pages: [
            ...prevInputs.pages,
            {
              ...page,
              id: uuidv4(),
              visible: true,
              reference: mainPage.reference,
              templatePageId: mainPage.templatePageId
            }
          ]
        }));

        setSuccessMessage('pages.addedSuccessfully');
      } else {
        setExperienceInputs(prevInputs => {
          const resetRatingShareFields =
            prevInputs.pages.filter(
              p => p.id === page.id && p.contentPageReference === prevInputs.ratingShareTargetTemplatePageReference
            )?.length > 0;

          const resetQuizClosedPageReference =
            prevInputs.pages.filter(
              p => p.id === page.id && p.contentPageReference === prevInputs.quizClosedPageReference
            )?.length > 0;
          const resetQuizWinnerPageReference =
            prevInputs.pages.filter(
              p => p.id === page.id && p.contentPageReference === prevInputs.quizWinnerPageReference
            )?.length > 0;
          const resetQuizNoWinnerPageReference =
            prevInputs.pages.filter(
              p => p.id === page.id && p.contentPageReference === prevInputs.quizNoWinnerPageReference
            )?.length > 0;
          const resetQuizWrongPageReference =
            prevInputs.pages.filter(
              p => p.id === page.id && p.contentPageReference === prevInputs.quizWrongPageReference
            )?.length > 0;
          const resetQuizCorrectPageReference =
            prevInputs.pages.filter(
              p => p.id === page.id && p.contentPageReference === prevInputs.quizCorrectPageReference
            )?.length > 0;

          const previousContentPageReference = prevInputs.pages.filter(
            prevPage => prevPage.id === page.id && prevPage.contentPageReference !== page.contentPageReference
          )?.[0]?.contentPageReference;
          const resetQuizPageFields =
            previousContentPageReference?.length > 0 &&
            prevInputs.quizQuestions.some(q => q.contentPageReference === previousContentPageReference);

          return {
            ...prevInputs,
            pages: prevInputs.pages.map(p => {
              return p.id === page.id ? page : p;
            }),
            ratingShareTargetTemplatePageReference: resetRatingShareFields
              ? ''
              : prevInputs.ratingShareTargetTemplatePageReference,
            ratingShareButtonTitle: resetRatingShareFields ? '' : prevInputs.ratingShareButtonTitle,
            quizQuestions: resetQuizPageFields
              ? prevInputs.quizQuestions.map(question => {
                  return {
                    ...question,
                    contentPageReference:
                      question.contentPageReference !== previousContentPageReference
                        ? question.contentPageReference
                        : ''
                  };
                })
              : [...prevInputs.quizQuestions],
            quizClosedPageReference: resetQuizClosedPageReference ? '' : prevInputs.quizClosedPageReference,
            quizWinnerPageReference: resetQuizWinnerPageReference ? '' : prevInputs.quizWinnerPageReference,
            quizNoWinnerPageReference: resetQuizNoWinnerPageReference ? '' : prevInputs.quizNoWinnerPageReference,
            quizWrongPageReference: resetQuizWrongPageReference ? '' : prevInputs.quizWrongPageReference,
            quizCorrectPageReference: resetQuizCorrectPageReference ? '' : prevInputs.quizCorrectPageReference
          };
        });

        setSuccessMessage('pages.updatedSuccessfully');
      }
    },
    [setExperienceInputs, experienceInputs.pages]
  );

  const handleDeletePage = useCallback(
    pageToDelete => {
      setExperienceInputs(prev => {
        const resetRatingShareFields =
          prev.ratingShareTargetTemplatePageReference === pageToDelete.contentPageReference;
        const resetQuizClosedPageReference = prev.quizClosedPageReference === pageToDelete.contentPageReference;
        const resetQuizWinnerPageReference = prev.quizWinnerPageReference === pageToDelete.contentPageReference;
        const resetQuizNoWinnerPageReference = prev.quizNoWinnerPageReference === pageToDelete.contentPageReference;
        const resetQuizWrongPageReference = prev.quizWrongPageReference === pageToDelete.contentPageReference;
        const resetQuizCorrectPageReference = prev.quizCorrectPageReference === pageToDelete.contentPageReference;
        return {
          ...prev,
          ratingShareTargetTemplatePageReference: resetRatingShareFields
            ? ''
            : prev.ratingShareTargetTemplatePageReference,
          ratingShareButtonTitle: resetRatingShareFields ? '' : prev.ratingShareButtonTitle,
          pages: prev.pages.filter(page => page.id !== pageToDelete.id),
          quizQuestions: prev.quizQuestions.map(question => {
            return {
              ...question,
              contentPageReference:
                question.contentPageReference !== pageToDelete.contentPageReference ? question.contentPageReference : ''
            };
          }),
          quizClosedPageReference: resetQuizClosedPageReference ? '' : prev.quizClosedPageReference,
          quizWinnerPageReference: resetQuizWinnerPageReference ? '' : prev.quizWinnerPageReference,
          quizNoWinnerPageReference: resetQuizNoWinnerPageReference ? '' : prev.quizNoWinnerPageReference,
          quizWrongPageReference: resetQuizWrongPageReference ? '' : prev.quizWrongPageReference,
          quizCorrectPageReference: resetQuizCorrectPageReference ? '' : prev.quizCorrectPageReference
        };
      });
    },
    [setExperienceInputs]
  );

  const handleAddMediaFiles = useCallback(
    async mediaFiles => {
      setExperienceInputs(prevInputs => ({
        ...prevInputs,
        mediaFiles: [
          ...prevInputs.mediaFiles,
          ...mediaFiles.map(mf => ({ ...mf, mediaFileId: mf.id, id: uuidv4(), canBeDeleted: true }))
        ]
      }));
    },
    [setExperienceInputs]
  );

  const handleEditMediaFile = useCallback(
    async mediaFile => {
      if (
        experienceInputs.mediaFiles.some(
          mf =>
            mf.tag?.trim() === mediaFile.tag?.trim() &&
            (mf.imageTemplateId ? mf.imageTemplateId !== mediaFile.imageTemplateId : mf.id !== mediaFile.id)
        )
      ) {
        setErrorMessage('mediaFiles.validations.tagAlreadyExists');
        return;
      }

      const updatedMediaFile = experienceInputs.mediaFiles.filter(
        mf =>
          (mf.imageTemplateId && mf.imageTemplateId === mediaFile.imageTemplateId) ||
          (!mf.imageTemplateId && mf.id === mediaFile.id)
      )[0];

      if (updatedMediaFile) {
        setExperienceInputs(prevInputs => ({
          ...prevInputs,
          ratingFullImageTag:
            prevInputs.ratingFullImageTag !== updatedMediaFile.tag ? prevInputs.ratingFullImageTag : '',
          ratingHalfImageTag:
            prevInputs.ratingHalfImageTag !== updatedMediaFile.tag ? prevInputs.ratingHalfImageTag : '',
          ratingssEmptyImageTag:
            prevInputs.ratingEmptyImageTag !== updatedMediaFile.tag ? prevInputs.ratingEmptyImageTag : '',
          mediaFiles: prevInputs.mediaFiles.map(mf => {
            return (mediaFile.imageTemplateId && mf.imageTemplateId === updatedMediaFile.imageTemplateId) ||
              (!mf.imageTemplateId && mf.id === updatedMediaFile.id)
              ? { ...mediaFile }
              : mf;
          }),
          pages: prevInputs.pages.map(page => {
            return {
              ...page,
              backgroundImageTag: page.backgroundImageTag !== updatedMediaFile.tag ? page.backgroundImageTag : ''
            };
          }),
          icons: prevInputs.icons.map(icon => {
            return {
              ...icon,
              iconImageTag: icon.iconImageTag !== updatedMediaFile.tag ? icon.iconImageTag : '',
              targetImageTag: icon.targetImageTag !== updatedMediaFile.tag ? icon.targetImageTag : ''
            };
          })
        }));

        setSuccessMessage('images.addedSuccessfully');
      } else {
        setExperienceInputs(prevInputs => ({
          ...prevInputs,
          mediaFiles: [...prevInputs.mediaFiles, { ...mediaFile }]
        }));

        setSuccessMessage('images.updatedSuccessfully');
      }
    },
    [setExperienceInputs, experienceInputs]
  );

  const handleDeleteMediaFile = useCallback(
    mediaFileToDelete => {
      setExperienceInputs(prevInputs => {
        return {
          ...prevInputs,
          ratingEmptyImageTag:
            prevInputs.ratingEmptyImageTag !== mediaFileToDelete.tag ? prevInputs.ratingEmptyImageTag : '',
          ratingHalfImageTag:
            prevInputs.ratingHalfImageTag !== mediaFileToDelete.tag ? prevInputs.ratingHalfImageTag : '',
          ratingFullImageTag:
            prevInputs.ratingFullImageTag !== mediaFileToDelete.tag ? prevInputs.ratingFullImageTag : '',
          unknownTamperImageTag:
            prevInputs.unknownTamperImageTag !== mediaFileToDelete.tag ? prevInputs.unknownTamperImageTag : '',
          openTamperImageTag:
            prevInputs.openTamperImageTag !== mediaFileToDelete.tag ? prevInputs.openTamperImageTag : '',
          closeTamperImageTag:
            prevInputs.closeTamperImageTag !== mediaFileToDelete.tag ? prevInputs.closeTamperImageTag : '',
          successfulAuthenticationImageTag:
            prevInputs.successfulAuthenticationImageTag !== mediaFileToDelete.tag
              ? prevInputs.successfulAuthenticationImageTag
              : '',
          unsuccessfulAuthenticationImageTag:
            prevInputs.unsuccessfulAuthenticationImageTag !== mediaFileToDelete.tag
              ? prevInputs.unsuccessfulAuthenticationImageTag
              : '',
          experienceDefaultImage:
            prevInputs.experienceDefaultImage !== mediaFileToDelete.tag ? prevInputs.experienceDefaultImage : '',
          experienceDefaultBackgroundImage:
            prevInputs.experienceDefaultBackgroundImage !== mediaFileToDelete.tag
              ? prevInputs.experienceDefaultBackgroundImage
              : '',
          mediaFiles: prevInputs.mediaFiles.filter(file => file.id !== mediaFileToDelete.id),
          pages: prevInputs.pages.map(page => {
            return {
              ...page,
              backgroundImageTag: page.backgroundImageTag !== mediaFileToDelete.tag ? page.backgroundImageTag : ''
            };
          }),
          icons: prevInputs.icons.map(icon => {
            return {
              ...icon,
              iconImageTag: icon.iconImageTag !== mediaFileToDelete.tag ? icon.iconImageTag : '',
              targetImageTag: icon.targetImageTag !== mediaFileToDelete.tag ? icon.targetImageTag : ''
            };
          }),
          articles: prevInputs.articles.map(article => {
            return {
              ...article,
              imageTag: article.imageTag !== mediaFileToDelete.tag ? article.imageTag : ''
            };
          })
        };
      });
    },
    [setExperienceInputs]
  );

  const handleAddIcon = useCallback(
    async icon => {
      setExperienceInputs(prevInputs => ({
        ...prevInputs,
        icons: [
          ...prevInputs.icons,
          {
            id: uuidv4(),
            ...icon
          }
        ]
      }));
    },
    [setExperienceInputs]
  );

  const handleDeleteIcon = useCallback(
    iconToDelete => {
      setExperienceInputs(prevInputs => {
        return { ...prevInputs, icons: prevInputs.icons.filter(i => i.id !== iconToDelete.id) };
      });
    },
    [setExperienceInputs]
  );

  const handleEditArticle = useCallback(
    async article => {
      if (
        (experienceInputs.articles || []).some(
          a => a.name?.trim().toLowerCase() === article.name?.trim().toLowerCase() && a.id !== article.id
        )
      ) {
        setErrorMessage('articles.validations.articleAlreadyExists');
        return;
      }

      const articleRatingItems = article.ratingItems || [];
      if (
        articleRatingItems.some(
          rating =>
            articleRatingItems.findIndex(
              r => r.name?.trim().toLowerCase() === rating.name?.trim().toLowerCase() && r.id !== rating.id
            ) >= 0
        )
      ) {
        setErrorMessage('articles.validations.ratingNameAlreadyExists');
        return;
      }

      if (
        articleRatingItems.some(
          rating =>
            articleRatingItems.findIndex(
              r => r.reference?.trim().toLowerCase() === rating.reference?.trim().toLowerCase() && r.id !== rating.id
            ) >= 0
        )
      ) {
        setErrorMessage('articles.validations.ratingReferenceAlreadyExists');
        return;
      }

      if (
        articleRatingItems.some(
          rating =>
            articleRatingItems.findIndex(
              r =>
                r.order?.toString().trim().toLowerCase() === rating.order?.toString().trim().toLowerCase() &&
                r.id !== rating.id
            ) >= 0
        )
      ) {
        setErrorMessage('articles.validations.ratingOrderAlreadyExists');
        return;
      }

      if (!article.id) {
        setExperienceInputs(prevInputs => ({
          ...prevInputs,
          articles: [...prevInputs.articles, { ...article, id: uuidv4() }]
        }));

        setSuccessMessage('articles.addedSuccessfully');
      } else {
        setExperienceInputs(prevInputs => ({
          ...prevInputs,
          articles: prevInputs.articles.map(a => {
            return a.id === article.id ? { ...article } : a;
          })
        }));
        setSuccessMessage('articles.updatedSuccessfully');
      }
    },
    [setExperienceInputs, experienceInputs.articles]
  );

  const handleDeleteArticle = useCallback(
    articlesToDelete => {
      setExperienceInputs(prev => {
        let articles = prev.articles;

        articlesToDelete.forEach(articleToDelete => {
          articles = articles.filter(f => f.id !== articleToDelete.id);
        });

        return { ...prev, articles: articles };
      });
    },
    [setExperienceInputs]
  );

  const handleEditQuizQuestion = useCallback(
    async question => {
      if (
        (experienceInputs.quizQuestions || []).some(
          q => q.name?.trim().toLowerCase() === question.name?.trim().toLowerCase() && q.id !== question.id
        )
      ) {
        setErrorMessage('quizQuestions.validations.questionAlreadyExists');
        return;
      }

      const questionAnswers = question.answers || [];
      if (
        questionAnswers.some(
          question =>
            questionAnswers.findIndex(
              r => r.name?.trim().toLowerCase() === question.name?.trim().toLowerCase() && r.id !== question.id
            ) >= 0
        )
      ) {
        setErrorMessage('quizQuestionAnswers.validations.answerAlreadyExists');
        return;
      }
      if (questionAnswers.length < 2) {
        setErrorMessage('quizQuestionAnswers.validations.answerAtLeastTwo');
        return;
      }
      if (questionAnswers.filter(question => question.isCorrect).length !== 1) {
        setErrorMessage('quizQuestionAnswers.validations.answerOnlyOneCorrect');
        return;
      }

      if (!question.id) {
        setExperienceInputs(prevInputs => ({
          ...prevInputs,
          quizQuestions: [...prevInputs.quizQuestions, { ...question, id: uuidv4() }]
        }));

        setSuccessMessage('quizQuestions.addedSuccessfully');
      } else {
        setExperienceInputs(prevInputs => ({
          ...prevInputs,
          quizQuestions: prevInputs.quizQuestions.map(a => {
            return a.id === question.id ? { ...question } : a;
          })
        }));
        setSuccessMessage('quizQuestions.updatedSuccessfully');
      }
    },
    [setExperienceInputs, experienceInputs.quizQuestions]
  );

  const handleDeleteQuizQuestion = useCallback(
    questionsToDelete => {
      setExperienceInputs(prev => {
        let quizQuestions = prev.quizQuestions;

        questionsToDelete.forEach(questionToDelete => {
          quizQuestions = quizQuestions.filter(f => f.id !== questionToDelete.id);
        });

        return { ...prev, quizQuestions: quizQuestions };
      });
    },
    [setExperienceInputs]
  );

  function resetErrorAndSuccessMessages() {
    setErrorMessage(null);
    setSuccessMessage(null);
  }

  return {
    experienceInputs,
    handleChange,
    handleSwitchChange,
    handleTemplateAutocomplete,
    handleEditField,
    handleDeleteFields,
    handleAddPage,
    handleDeletePage,
    handleAddMediaFiles,
    handleEditMediaFile,
    handleDeleteMediaFile,
    handleAddIcon,
    handleDeleteIcon,
    handleEditArticle,
    handleDeleteArticle,
    handleEditQuizQuestion,
    handleDeleteQuizQuestion,
    handleLanguageAutocomplete,
    successMessage,
    setSuccessMessage,
    errorMessage,
    setErrorMessage,
    resetErrorAndSuccessMessages
  };
}
