import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { Form, Summary, Input } from '@engloba-tech/englobity';
import { NAME_SPACE } from 'i18n';
import { isSkeletonLoading } from 'shared';
import { useFormQuestionStyles } from './formQuestion.styles';
import { useQuestionForm } from './useQuestionForm';
import { ViewAnswers } from './ViewAnswers';
import { ContentPageSelect } from 'pages/Experiences/Edit/shared';

export function FormQuestion({
  question,
  pages,
  formRef,
  onSubmit,
  successMessage,
  errorMessage,
  resetErrorAndSuccessMessages,
  onClose,
  disabled
}) {
  const { t } = useTranslation(NAME_SPACE.EXPERIENCES);
  const classes = useFormQuestionStyles();
  const { questionInputs, setQuestionInputs, handleChange, handleAddAnswer, handleDeleteAnswer } =
    useQuestionForm(question);

  useEffect(() => {
    if (successMessage && !question.id) {
      setQuestionInputs({});
    } else if (successMessage) {
      onClose();
    }
  }, [successMessage, question.id]);

  function handleSubmit() {
    resetErrorAndSuccessMessages();
    onSubmit(questionInputs);
  }

  return (
    <>
      {(successMessage || errorMessage) && (
        <Grid item xs={12}>
          <Summary
            text={successMessage ? t(successMessage) : t(errorMessage)}
            severity={successMessage ? 'success' : 'error'}
          />
        </Grid>
      )}
      <Form
        className={classes.formQuestion}
        errors={errorMessage}
        elementRef={formRef}
        onSubmit={handleSubmit}
        onKeyPress={e => {
          if (e.which === 13) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        autoComplete="off"
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              isLoading={isSkeletonLoading(questionInputs.name)}
              required
              name={t('quizQuestions.properties.name')}
              label={t('quizQuestions.properties.name')}
              variant="outlined"
              value={questionInputs.name || ''}
              onChange={handleChange('name')}
              inputProps={{ 'aria-label': t('quizQuestions.properties.name') }}
              validators={['required', 'maxStringLength:256']}
              errorMessages={[t('validations.required'), t('common:validations.maxStringLength', { length: 256 })]}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6}>
            <ContentPageSelect
              variant="outlined"
              withAnyLabel
              label={t('quizQuestions.properties.contentPageReference')}
              value={questionInputs.contentPageReference || ''}
              onChange={handleChange('contentPageReference')}
              options={pages}
              fullWidth
              disabled={disabled}
              className={classes.select}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12} className={classes.answersContainer}>
            <ViewAnswers
              answers={questionInputs.answers}
              onChange={handleChange}
              onAdd={handleAddAnswer}
              onDelete={handleDeleteAnswer}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Form>
    </>
  );
}

FormQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  pages: PropTypes.array,
  formRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    message: PropTypes.string,
    detail: PropTypes.string
  }),
  successMessage: PropTypes.string,
  errorMessage: PropTypes.string,
  resetErrorAndSuccessMessages: PropTypes.func,
  onClose: PropTypes.func,
  disabled: PropTypes.bool
};
