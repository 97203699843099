import { useMemo } from 'react';
import { FILTER_TYPE } from 'shared/ViewTable/AdvancedFilters';
import { useExperienceTemplates } from './useExperienceTemplates';

export function useExperiencesBuildTable(inputs, t) {
  const { templates } = useExperienceTemplates(true);

  const publishedElements = useMemo(() => {
    const PUBLISHED = {
      true: 'common:actions.yes',
      false: 'common:actions.no'
    };

    var publishMapped = Object.keys(PUBLISHED).map(key => {
      return {
        value: key,
        name: t(PUBLISHED[key])
      };
    });

    return [{ value: -1, name: t('common:selectFirstOptionComboFilter') }, ...publishMapped];
  }, [t]);

  const headCells = useMemo(() => {
    return [
      { id: 'name', numeric: false, disablePadding: false, label: t('properties.name') },
      {
        id: 'template',
        numeric: false,
        disablePadding: false,
        label: t('properties.template'),
        filterType: FILTER_TYPE.COMBO,
        filterValues: templates,
        filterBy: 'templateId'
      },
      {
        id: 'language',
        numeric: false,
        disablePadding: false,
        label: t('properties.language')
      },
      {
        id: 'isPublished',
        numeric: false,
        disablePadding: false,
        label: t('properties.isPublished'),
        filterType: FILTER_TYPE.COMBO,
        filterValues: publishedElements
      }
    ];
  }, [t, publishedElements, templates]);

  const rows = useMemo(() => {
    if (!inputs) {
      return [];
    }
    return inputs.items.map(experience => ({
      name: experience.name,
      id: experience.id,
      template: experience.template,
      language: experience.language,
      isPublished: experience.isPublished,
      canBeDeleted:
        !experience.isPublished &&
        !experience.hasTags &&
        !experience.hasRatedScans &&
        !experience.hasQuizAttempts &&
        !experience.hasPrizes
    }));
  }, [inputs]);

  return { headCells, rows };
}
