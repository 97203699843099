import { makeStyles } from '@material-ui/core/styles';

export const useFormQuestionStyles = makeStyles(theme => ({
  formQuestion: {
    flexGrow: 1
  },
  select: {
    minWidth: '8rem',
    fontSize: 'inherit'
  },
  answersContainer: {
    paddingLeft: `${24}px !important`,
    paddingRight: `${24}px !important`,
    paddingTop: `${0}px !important`,
    paddingBottom: `${0}px !important`
  }
}));
