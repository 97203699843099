import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from '@material-ui/lab';
import { CustomHtmlEditor } from 'shared';
import { isSkeletonLoading } from 'shared/skeletonFactory';
import { viewContentConfiguration } from './viewContent.config';
import { UserContext, PLAN_ACCESS } from 'auth';

export function ViewContent({ onChange, content }) {
  const { hasMinimumPlan } = useContext(UserContext);
  const showRatingComponents = hasMinimumPlan(PLAN_ACCESS.ADVANCED);
  const showAuthComponents = hasMinimumPlan(PLAN_ACCESS.STANDARD);
  const showQuizComponents = hasMinimumPlan(PLAN_ACCESS.ADVANCED);

  const configurationByPlan = useMemo(() => {
    let config = { ...viewContentConfiguration };
    if (config && config.pluginsOpts['experiences-plugin']) {
      config.pluginsOpts['experiences-plugin'].blocks = [
        ...['mainContainer', 'pageReference', 'cookiesButton', 'customForm', 'shareContainer', 'shareButton'],
        ...(showRatingComponents ? ['ratingsModalButton', 'ratingsShareableFields'] : []),
        ...(showAuthComponents ? ['authenticationControls'] : []),
        ...(showQuizComponents ? ['quizForm'] : [])
      ];
    }
    return config;
  }, [showRatingComponents, showAuthComponents, showQuizComponents]);

  const saveCommandEventHandler = {
    name: 'run:save-command',
    action: model => {
      if (onChange) {
        const css = model.css ? `<style>${model.css}</style>` : '';
        const newEvent = {
          target: {
            name: 'content',
            value: `${model.html}${css}`
          }
        };
        onChange(newEvent);
      }
    }
  };

  return isSkeletonLoading(content) ? (
    <Skeleton height={600} />
  ) : (
    <CustomHtmlEditor
      pages={[{ id: 'first', content: content }]}
      selectedPageId={'first'}
      eventHandlers={[saveCommandEventHandler]}
      customConfiguration={configurationByPlan}
    />
  );
}

ViewContent.propTypes = {
  onChange: PropTypes.func,
  content: PropTypes.string
};
