import { makeStyles } from '@material-ui/core/styles';

export const useViewAnswerStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(1)}px ${theme.spacing(0)}px  !important`,
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: theme.palette.primary.light
    }
  },
  actionsContainer: {
    textAlign: 'center'
  }
}));
