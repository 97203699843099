import { makeStyles } from '@material-ui/core/styles';

export const useViewAnswersStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'end'
  },
  titleContainer: {
    marginTop: theme.spacing(3),
    padding: '0px !important'
  },
  subtitle: {
    display: 'flex',
    backgroundColor: '#fdfbf6',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.primary.main,
    padding: '0.7rem 24px !important',
    '& .MuiTypography-body1': {
      flex: 1
    }
  },
  title: {
    fontWeight: theme.font.weight.bold,
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
    lineHeight: 2.2
  },
  answersContainer: {
    backgroundColor: theme.palette.primary.light,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px  !important`,
    maxHeight: '40vh',
    minHeight: '40vh',
    overflowY: 'auto'
  },
  divider: { margin: '15px 16px', backgroundColor: `${theme.palette.primary.main} !important` }
}));
